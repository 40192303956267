import { Box, Link } from "@chakra-ui/react";
import styles from "../styles/Home.module.css";
import { SwiperSlide } from "swiper/react";
import { goCart, goCartBackOrder } from "../provider/cart";
import { GetCookie } from "../provider/common";
import { useRouter } from "next/router";

import { useRecoilState } from "recoil";
import { cartCntState, quoteCntState } from "../atoms/state";
import { GetCartCnt } from "../pages/api/cart";
const axios = require("axios").default;
import { useState } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

export function RepeatSwiper(productData, isLoginCheck, openModal) {
  const router = useRouter();
  const [cartCnt, setCartCnt] = useRecoilState(cartCntState);
  const [quoteCnt, setQuoteCnt] = useRecoilState(quoteCntState);

  const html = [];
  // 베스트,특가,핫딜,신상품이 1개가 아닐 테니까 1개인 경우는 이벤트 기획전으로 가정한다.
  //coupon data 가 넘어오면 하단 Swiper 실행되기전에 Return 하도록 한다.
  if (productData.length === 1) {
    html.push(
      <SwiperSlide className={styles.main_slide}>
        <ul>
          {" "}
          <li
            style={{
              // width: "calc(24% - 5px)",
              // margin: "15px 5px 10px",
              width: "100%",
            }}
          >
            <Link href={productData[0].link} className="main_swiper_ad_img">
              <Box
                className={styles.pd_detail_img}
                style={{
                  backgroundImage: `url(${productData[0].img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "-0 50%",
                  height: "73%",
                }}
              ></Box>
              <Box
                className={styles.pd_detail_ex}
                style={{ backgroundColor: "#ccc", height: "27%" }}
              >
                <span style={{ color: "#222", width: "100%" }}>
                  {productData[0].title}
                </span>
                <span
                  style={{
                    color: "#222",
                    top: "unset",
                    bottom: "0",
                    left: "unset",
                    right: "5px",
                    fontSize: "13px",
                  }}
                >
                  {productData[0].date}
                </span>
              </Box>
            </Link>
          </li>
        </ul>
      </SwiperSlide>
    );
    return html;
  }

  // 화면 노출 개수
  const exposureCount = 16;
  // SwiperSlide 개수 그리는 공식 (소수점이 있으면 무조건 올림)
  const swiperSlideCount = Math.ceil(productData.length / exposureCount);
  // 16개 이기 때문에 0부터 15까지 함
  let start = 0;
  let end = 15;

  const checkCartCaculate = async (productData) => {
    await goCart(productData);
    //장바구니 개수를 다시 세는 로직 추가
    setCartCnt(await GetCartCnt());
  };

  const checkBackOrderCaculate = async (productData) => {
    let result = window.confirm(
      "이 제품은 현재 재고가 없으며, \n주문 후 제작 수입에 다소 시간이 소요될 수 있습니다."
    );
    if (result) {
      checkCartCaculate(productData);
    }
  };

  const combineBotAsked = async (isLoginCheck, data) => {
    // 로그인이 되어있으면 견적바구니로 바로 요청
    if (isLoginCheck === "1") {
      const body = [];

      body.push({
        it_id: data.it_id,
        it_brand: data.it_brand,
        it_name: data.it_name,
        it_maker: data.it_maker,
        qty: data.it_stock_qty,
      });

      try {
        const URL = process.env.PANDA_API_URL + "/api/mall/insertQuoteLists";
        const result = await axios.post(URL, body, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${await GetCookie("token")}`,
          },
        });
        if (result.data.status === 200) {
          setQuoteCnt(result.data.quoteAllCnt);
          if (
            confirm(
              "견적바구니에 저장되었습니다. 견적바구니로 이동하시겠습니까?"
            ) === true
          ) {
            router.push(`/marketplace/cart`);
            return;
          }
        } else {
          alert("견적바구니에 저장이 실패하였습니다.");
        }
      } catch (e) {
        alert("견적바구니에 저장이 실패하였습니다.");
      }
    } else {
      alert(
        "컴바인봇 견적 문의하기는 로그인 후에 요청 가능합니다.로그인 페이지로 이동합니다."
      );
      const href = `/auth/login`;
      router.push(href);
    }
    // 미 로그인 되어있으면 로그인 화면으로 간다.
  };

  // 몰뷰로 이동할 것인가? 컴바인봇 견적 문의하기로 할 것이냐?
  const isShoppingmallOrCombineBot = async (isLoginCheck, datum) => {
    if (datum.it_soldout === 2) {
      router.push(`/parts/inventory/${datum.it_id}`);
    } else {
      //쇼핑몰소개
      router.push(`/parts/mallview/${datum.it_id}`);
    }
  };

  //Swiper 그리는 것 반복
  for (let i = 0; i < swiperSlideCount; i++) {
    const items = [];

    //내부에 화면 출력하기
    for (let j = start; j <= end; j++) {
      // undefined 면 종료
      if (productData[j] !== undefined) {
        items.push(
          <li>
            <Link
              onClick={() =>
                isShoppingmallOrCombineBot(isLoginCheck, productData[j])
              }
            >
              {productData[j].it_img1 === "" ||
              productData[j].it_img1 === null ||
              productData[j].it_img1 === undefined ||
              productData[j].it_img1 === "undefined" ? (
                <Box
                  className={styles.pd_detail_img}
                  style={{
                    backgroundImage: "url(./images/no_img.png)",
                  }}
                ></Box>
              ) : productData[j].it_img1 ? (
                <Box
                  className={styles.pd_detail_img}
                  style={{
                    backgroundImage: `url(${productData[j].it_img1})`,
                  }}
                ></Box>
              ) : (
                ""
              )}
            </Link>

            {productData[j].it_soldout === 1 ? (
              // 백오더 제품
              <Box
                className={styles.pd_detail_ex}
                value={productData[j].it_id}
                onClick={() => checkBackOrderCaculate(productData[j])}
              >
                <span>{productData[j].it_name}</span>
                <span>{productData[j].it_brand}</span>
                <span className={styles.pd_detail_before_price}>
                  ₩ {parseInt(productData[j].it_price_before).toLocaleString()}{" "}
                </span>
                <span>
                  <span>
                    할인가 : ₩ {productData[j].it_price.toLocaleString()}
                  </span>{" "}
                </span>
                <span className={styles.pd_detail_back_order_btn}>
                  <button value={productData[j].it_id}>
                    <i></i>
                  </button>
                </span>
                <button
                  value={productData[j].it_id}
                  className={styles.pd_detail_btn_other_back_order}
                >
                  Back-Order
                </button>
              </Box>
            ) : productData[j].it_soldout === 2 ? (
              // 재고공유_컴바인봇 견적 연결
              <Box
                className={styles.pd_detail_ex}
                value={productData[j].it_id}
                onClick={() =>
                  isShoppingmallOrCombineBot(isLoginCheck, productData[j])
                }
              >
                <span>{productData[j].it_name}</span>
                <span>{productData[j].it_brand}</span>
                <span className={styles.pd_detail_inventory_number}>
                  재고 {productData[j].it_stock_qty.toLocaleString()}
                </span>
                <span>
                  <span className={styles.pd_detail_right}>바로출고</span>
                  <span className={styles.pd_detail_special}>특가제공</span>
                </span>
                <span className={styles.pd_detail_inventory_btn}>
                  <Link value={productData[j].it_id}></Link>
                </span>
                <Link
                  value={productData[j].it_id}
                  className={styles.pd_detail_btn_other_inventory}
                >
                  견적문의
                </Link>
              </Box>
            ) : (
              // 일반 상품 결제 click
              <Box
                className={styles.pd_detail_ex}
                value={productData[j].it_id}
                onClick={() => checkCartCaculate(productData[j])}
              >
                <span>{productData[j].it_name}</span>
                <span>{productData[j].it_brand}</span>
                <span className={styles.pd_detail_before_price}>
                  ₩ {parseInt(productData[j].it_price_before).toLocaleString()}{" "}
                </span>
                <span>
                  <span>
                    할인가 : ₩ {productData[j].it_price.toLocaleString()}
                  </span>{" "}
                </span>
                <span>
                  <i className="xi-cart-o"></i>
                </span>
              </Box>
            )}
          </li>
        );
      }
    }
    html.push(
      <SwiperSlide className={styles.main_slide}>
        <ul>{items}</ul>
      </SwiperSlide>
    );
    //
    start = start + exposureCount;
    end = end + exposureCount;
  }
  return html;
}
