import {
  Box,
  Modal as ChakraModal,
  Text,
  Button,
  Link,
  Heading,
  Image,
} from "@chakra-ui/react";
import { useEffect } from "react";
import styles from "../styles/Home.module.css";

export function QuotationModal({ isOpen, onClose, productData }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <ChakraModal isOpen={isOpen}>
      <Box className="quotation_modal_box">
        <Text onClick={onClose}>
          <i className="xi-close"></i>
        </Text>
        <Heading fontSize="4xl">{productData.it_brand}</Heading>
        <Box className="product_detail">
          <Box className="product_thumb">
            <Box className="product_thumb_img">
              <Image
                src={
                  productData.it_img1 === null || productData.it_img1 === ""
                    ? "/images/no_img.png"
                    : productData.it_img1
                }
              />
            </Box>
            <Box className="product_thumb_btn"></Box>
          </Box>
          <Box className="product_txt">
            <Box className="product_title">
              <Text className="product_name">{productData.it_name}</Text>
              <span className={styles.pd_detail_right}>바로출고</span>
              <span className={styles.pd_detail_special}>특가제공</span>
            </Box>
            <Box className="product_info">
              <ul>
                <li>유통사 : {productData.it_maker}</li>
                <li>MPN : {productData.it_id}</li>
                <li>SKU : {productData.it_8}</li>
                <li>Packaging : {productData.it_skin}</li>
                {/* <li>LifeCycle : {productData.it_4_subj}</li> */}
                <li>재고 : {productData.it_stock_qty}</li>
                <li>최소 구매수량(MOQ) : {productData.it_buy_min_qty}</li>
                <li>구매단위(Multiple) : {productData.it_buy_max_qty}</li>
              </ul>
            </Box>
            <Box className="product_btn">
              <Button
              // onClick={}
              >
                견적문의
              </Button>
              <Link href={`/parts/inventory/${productData.it_id}`}>
                상세페이지
              </Link>
              {/* <Button>찜 목록</Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraModal>
  );
}
